em-emoji-picker {
  --background-rgb: 85, 170, 255;
  --border-radius: var(--#{$prefix}border-radius-lg);
  --color-border: var(--#{$prefix}gray-200);
  --font-family: var(--#{$prefix}font-base);
  --rgb-accent: var(--#{$prefix}primary-rgb);
  --rgb-background: var(--#{$prefix}quaternary-bg-rgb);
  --rgb-color: var(--#{$prefix}body-color-rgb);
  --rgb-input: var(--#{$prefix}body-bg-rgb);
  --shadow: var(--#{$prefix}box-shadow);
  border: 1px solid var(--#{$prefix}gray-200);
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  z-index: 1;
}

.chat-emoji-picker {
  em-emoji-picker {
    bottom: 2rem;
    top: unset;
    left: auto;
    right: 2%;
  }
}
